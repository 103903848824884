import React from 'react';
import { Row, Col, Button, Modal, Collapse, Switch } from 'antd';
import { getCookie, setCookie } from '../../util/cookie';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { safeParseJSON } from '../../util/string';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { PRIMARY_COLOR, RED } from '../../constants/ThemeSetting';

const { Panel } = Collapse;

const COOKIE_ACCEPTED_WITH_PREF = 3;
const COOKIE_ACCEPTED = 2;
const COOKIE_DECLINED = 1;
const COOKIE_CONSENT_RESPONSE = 'ks_cookie_reply';
const COOKIE_CONSENT_EXPIRY = 365;
const COOKIE_CONSENT_PREFENCE = 'ks_cookie_pref';
const DEFAULT_PREFENCE = {
    required: true,
    measure: true,
    marketing: true
};

const PanelIcon = ({ accepted }) => {
    if (accepted) {
        return <CheckOutlined style={{ color: PRIMARY_COLOR }} />
    } else {
        return <CloseOutlined style={{ color: RED }} />
    }
}

export const CookieConsentPreferenceModal = ({ onClickChoice, onCancel, visible }) => {

    const { t } = useTranslation(['cookie']);

    const loadPreference = () => {
        const pref = getCookie({ name: COOKIE_CONSENT_PREFENCE });
        let prefObj = safeParseJSON(pref);

        if (!prefObj) {
            return { ...DEFAULT_PREFENCE };
        }

        return prefObj;
    }

    const [cookieSettings, setCookieSettings] = React.useState(loadPreference());

    return (
        <Modal
            title={t('cookie:preferences_title')}
            visible={visible}
            onCancel={onCancel}
            footer={
                <div className='gx-d-flex gx-justify-content-between'>
                    <Button
                        className='gx-btn-info'
                        onClick={() => {
                            onClickChoice(COOKIE_ACCEPTED_WITH_PREF, cookieSettings);
                        }}
                        type="ghost">
                        {t('cookie:save_preferences')}
                    </Button>
                    <div>
                        <Button
                            onClick={() => {
                                setCookieSettings({
                                    required: true,
                                    measure: false,
                                    marketing: false
                                });
                                onClickChoice(COOKIE_DECLINED);
                            }} type="default">{t('cookie:decline')}</Button>
                        <Button
                            onClick={() => {
                                setCookieSettings({ ...DEFAULT_PREFENCE });
                                onClickChoice(COOKIE_ACCEPTED);
                            }}
                            type="primary">{t('cookie:accept')}
                        </Button>
                    </div>
                </div>
            }
        >
            <p>
                {t('cookie:preferences_text')}
            </p>
            <p>
                {t('cookie:preferences_text_2')}
            </p>
            <Collapse defaultActiveKey={['1']}>
                <Panel header={t('cookie:required_title')} key="1" extra={<PanelIcon accepted={true} />}>
                    <p>{t('cookie:required_text')}</p>
                    <Switch
                        disabled
                        defaultChecked
                        checkedChildren={t('cookie:enable')}
                        unCheckedChildren={t('cookie:disable')}
                    />
                </Panel>
                <Panel header={t('cookie:measure_title')} key="2" extra={<PanelIcon accepted={cookieSettings.measure} />}>
                    <p>{t('cookie:measure_text')}</p>

                    <Switch
                        onChange={(checked) => {
                            setCookieSettings({ ...cookieSettings, measure: checked });
                        }}
                        checked={cookieSettings.measure}
                        checkedChildren={t('cookie:enable')}
                        unCheckedChildren={t('cookie:disable')}
                    />
                </Panel>
                <Panel header={t('cookie:marketing_title')} key="3" extra={<PanelIcon accepted={cookieSettings.marketing} />}>
                    <p>{t('cookie:marketing_text')}</p>
                    <Switch
                        onChange={(checked) => {
                            setCookieSettings({ ...cookieSettings, marketing: checked });
                        }}
                        checked={cookieSettings.marketing}
                        checkedChildren={t('cookie:enable')}
                        unCheckedChildren={t('cookie:disable')}
                    />
                </Panel>
            </Collapse>
        </Modal>
    )
}

export const onClickCookieChoice = (choice, settings = null) => {
    setCookie({ name: COOKIE_CONSENT_RESPONSE, value: choice, days: COOKIE_CONSENT_EXPIRY });

    if (choice === COOKIE_ACCEPTED) {
        setCookie({ name: COOKIE_CONSENT_PREFENCE, value: JSON.stringify(DEFAULT_PREFENCE), days: COOKIE_CONSENT_EXPIRY });
    } else if (choice === COOKIE_DECLINED) {
        setCookie({
            name: COOKIE_CONSENT_PREFENCE, value: JSON.stringify({
                required: true,
                measure: false,
                marketing: false
            }), days: COOKIE_CONSENT_EXPIRY
        });
    } else if (choice === COOKIE_ACCEPTED_WITH_PREF) {
        setCookie({ name: COOKIE_CONSENT_PREFENCE, value: JSON.stringify(settings), days: COOKIE_CONSENT_EXPIRY });
    }

}

const CookieConsent = () => {

    const { t } = useTranslation(['cookie']);

    const [showCookieConsent, setShowCookieConsent] = React.useState(true);
    const [showCookiePreference, setShowCookiePreference] = React.useState(false);

    const hasCookieConsent = () => {
        return Boolean(getCookie({ name: COOKIE_CONSENT_RESPONSE }));
    }

    const onClickChoice = (choice, settings = null) => {
        onClickCookieChoice(choice, settings);
        setShowCookieConsent(false);
    }

    if (hasCookieConsent() || !showCookieConsent) {
        return null;
    }

    return (
        <>
            <div className='gx-cookie-consent gx-w-100 gx-p-5' style={{
                display: showCookiePreference ? 'none' : 'block',
            }}>
                <Row>
                    <Col span={24}>
                        <p>
                            <Trans
                                i18nKey="cookie:main_text"
                                components={[<Link key="1" to="/legal/cookies"></Link>]}
                            />
                        </p>
                    </Col>
                    <Col xs={24}>
                        <div className='gx-d-flex gx-justify-content-between'>
                            <div>
                                <Button onClick={() => {
                                    setShowCookiePreference(true);
                                }} type="ghost gx-btn-info">{t('cookie:preferences')}</Button>

                                <Button
                                    className='gx-d-none gx-d-sm-inline-block'
                                    style={{ minWidth: 250 }}
                                    onClick={() => {
                                        onClickChoice(COOKIE_ACCEPTED);
                                    }}
                                    type="primary">{t('cookie:accept')}</Button>
                            </div>
                            <Button
                                onClick={() => {
                                    onClickChoice(COOKIE_DECLINED);
                                }} type="default">{t('cookie:decline')}</Button>
                        </div>
                    </Col>
                    <Col span={24} className='gx-d-block gx-d-sm-none'>
                        <Button
                            className='gx-w-100 gx-mt-3'                            
                            onClick={() => {
                                onClickChoice(COOKIE_ACCEPTED);
                            }}
                            type="primary">{t('cookie:accept')}</Button>
                    </Col>
                </Row>


            </div>
            <CookieConsentPreferenceModal visible={showCookiePreference} onClickChoice={onClickChoice}
                onCancel={() => {
                    setShowCookiePreference(false);
                }}
            />
        </>
    );
}

export default CookieConsent;