import { Layout, Row, Col, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { footerText } from 'util/config';
import moment from 'moment';
import config from 'config';
import { APP_NAME } from '../../constants/App';
import { includes } from 'lodash';
import { FacebookOutlined, InstagramOutlined, MessageOutlined } from '@ant-design/icons';

// const keepsportingEventsSectionsMapping = {
//     subscription: {
//         it: '/iscrizioni-online.html',
//         pt: '/inscricoes-online.html',
//         es: '/inscripcines-online.html',
//         en: '/online-subscriptions.html',
//         de: '/online-subscriptions.html',
//     },
//     promotion: {
//         it: '/promozione.html',
//         pt: '/promocao.html',
//         es: '/promotion.html',
//         en: '/promotion.html',
//         de: '/promotion.html',
//     },
//     eventManagement: {
//         it: '/gestione-evento.html',
//         pt: '/gerenciamento-de-eventos.html',
//         es: '/event-management.html',
//         en: '/event-management.html',
//         de: '/event-management.html',
//     },
//     timing: {
//         it: '/cronometraggi-21.html',
//         pt: '/cronometragem.html',
//         es: '/cronometraje.html',
//         en: '/timekeeping.html',
//         de: '/timekeeping.html',
//     },
//     contacts: {
//         it: '/contatti.html',
//         pt: '/contatos.html',
//         es: '/contactos.html',
//         en: '/contacts.html',
//         de: '/contacts.html',
//     },
//     workWithUs: {
//         it: '/lavora-con-noi.html',
//         pt: '/trabalhe-conosco.html',
//         es: '/trabaje-con-nosotros.html',
//         en: '/work-with-us.html',
//         de: '/work-with-us.html',
//     },
//     faq: {
//         it: '/faq-italia.html',
//         pt: '/faq-brasil.html',
//         es: '/faq-argentina.html',
//         en: '/faq-uk.html',
//         de: '/faq-uk.html',
//     },
//     global: {
//         it: '/global.html',
//         pt: '/global.html',
//         es: '/global.html',
//         en: '/global.html',
//         de: '/global.html',
//     },
// };

const Footer = () => {
    const { t, i18n } = useTranslation();

    const onChangeLocale = (e, locale) => {
        e.preventDefault();

        i18n.changeLanguage(locale, (err) => {
            if (err) return console.log('something went wrong loading', err);
            moment.locale(locale);
        });
    };

    const getLocationSlug = () => {
        switch (i18n.language) {
        case 'it':
            return 'italia';
        case 'pt':
            return 'brasil';
        default:
            return '';
        }
    };

    const getBlogUrl = () => {
        const baseBlogUrl = `${config.baseUrl}/blog`;

        if (includes(['pt', 'it'], i18n.language)) {
            return `${baseBlogUrl}/${i18n.language}`;
        }

        return `${baseBlogUrl}/it`;
    };

    // const getKeepsportingEventUrlBySection = (section) => {
    //     return `${config.eventBaseUrl}${get(keepsportingEventsSectionsMapping, `${section}.${i18n.language}`, '')}`;
    // };

    return (
        <Layout.Footer className="gx-bg-white gx-mt-5">
            <div className="gx-layout-footer-content">
                <Row gutter={[16, 16]} className="gx-mb-4 gx-mt-3">
                    <Col xs={12} sm={12} md={6}>
                        <div className="gx-footer-list">
                            <Typography.Title className="gx-fs-lg" level={3}>
                                {APP_NAME}
                            </Typography.Title>
                            <ul>
                                <li>
                                    <Link title={t('homepage')} to="/home">
                                        {t('homepage')}
                                    </Link>
                                </li>
                                <li>
                                    <Link title={t('contests')} to="/events">
                                        {t('contests')}
                                    </Link>
                                </li>
                                <li>
                                    <Link title={t('organizators')} to="/organizers">
                                        {t('organizators')}
                                    </Link>
                                </li>
                                <li>
                                    <Link title={t('rankings')} to="/rankings">
                                        {t('rankings')}
                                    </Link>
                                </li>
                                <li>
                                    <Link title={t('photos')} to="/photos">
                                        {t('photos')}
                                    </Link>
                                </li>
                                <li>
                                    <Link title={t('advertisement_title')} to="/advertisement">
                                        {t('advertisement_title')}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <div className="gx-footer-list">
                            <Typography.Title className="gx-fs-lg" level={3}>
                                {t('services')}
                            </Typography.Title>
                            <ul>
                                {/* <li><a rel="noreferrer" href={getKeepsportingEventUrlBySection('subscription')} target="_blank">{t('online_subscription')}</a></li> */}
                                <li><Link title={t('presentation_title')} to="/presentation">{t('presentation_title')}</Link></li>
                                {/* <li><a rel="noreferrer" href={getKeepsportingEventUrlBySection('subscription')} target="_blank">{t('online_subscription')}</a></li> */}
                                <li><Link title={t('online_subscription')} to="/online-subscription">{t('online_subscription')}</Link></li>
                                {/* <li><a rel="noreferrer" href={getKeepsportingEventUrlBySection('promotion')} target="_blank">{t('promotion')}</a></li> */}
                                <li><Link title={t('marketing')} to="/marketing">{t('marketing')}</Link></li>
                                {/* <li><a rel="noreferrer" href={getKeepsportingEventUrlBySection('eventManagement')} target="_blank">{t('event_management')}</a></li> */}
                                <li><Link title={t('timing')} to="/timing">{t('timing')}</Link></li>
                                <li><Link title={t('live_tracking')} to="/live-tracking">{t('live_tracking')}</Link></li>
                                <li><Link title={t('organization_title')} to="/back-office">{t('organization_title')}</Link></li>
                                <li><Link title={t('media_title')} to="/media-service">{t('media_title')}</Link></li>
                            </ul>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <div className="gx-footer-list">
                            <Typography.Title className="gx-fs-lg" level={3}>
                                {t('about_us')}
                            </Typography.Title>
                            <ul>
                                <li><Link title={t('advertisement_azienda')} to="/company">{t('advertisement_azienda')}</Link></li>
                                <li><Link title={t('contact_title')} to="/contact-us">{t('contact_title')}</Link></li>
                                <li><Link title={t('work_with_us')} to="/work-with-us">{t('work_with_us')}</Link></li>
                                <li><Link title={t('global')} to="/global">{t('global')}</Link></li>
                                <li><Link title={t('reference')} to="/reference">{t('reference')}</Link></li>
                            </ul>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <div className="gx-footer-list">
                            <Typography.Title className="gx-fs-lg" level={3}>
                                {t('follow_us')}
                            </Typography.Title>
                            <ul>
                                <li>
                                    <a rel="noreferrer" target="_blank" href={getBlogUrl()}>
                                        <MessageOutlined /> Blog
                                    </a>
                                </li>
                                <li>
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={`https://www.facebook.com/keepsporting${getLocationSlug()}`}
                                    >
                                        <FacebookOutlined /> Facebook
                                    </a>
                                </li>
                                <li>
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={`https://www.instagram.com/keepsporting${getLocationSlug()}/`}
                                    >
                                        <InstagramOutlined /> Instagram
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <div className="gx-d-block gx-d-md-inline-block gx-float-md-left">
                            <p className="gx-mb-4">{`${footerText} ${new Date().getFullYear()} - Keepsporting ltd - UTR 2223022181`}</p>
                            <a
                                className={`${i18n.language === 'de' ? 'gx-text-primary' : 'gx-text-grey'}`}
                                onClick={(e) => onChangeLocale(e, 'de')}
                                href="#"
                            >
                                Deutsch
                            </a>
                            &nbsp;<span className="gx-mr-1 gx-ml-1">|</span>&nbsp;
                            <a
                                className={`${i18n.language === 'en' ? 'gx-text-primary' : 'gx-text-grey'}`}
                                onClick={(e) => onChangeLocale(e, 'en')}
                                href="#"
                            >
                                English
                            </a>
                            &nbsp;<span className="gx-mr-1 gx-ml-1">|</span>&nbsp;
                            <a
                                className={`${i18n.language === 'es' ? 'gx-text-primary' : 'gx-text-grey'}`}
                                onClick={(e) => onChangeLocale(e, 'es')}
                                href="#"
                            >
                                Español
                            </a>
                            &nbsp;<span className="gx-mr-1 gx-ml-1">|</span>&nbsp;
                            <a
                                className={`${i18n.language === 'it' ? 'gx-text-primary' : 'gx-text-grey'}`}
                                onClick={(e) => onChangeLocale(e, 'it')}
                                href="#"
                            >
                                Italiano
                            </a>
                            &nbsp;<span className="gx-mr-1 gx-ml-1">|</span>&nbsp;
                            <a
                                className={`${i18n.language === 'pt' ? 'gx-text-primary' : 'gx-text-grey'}`}
                                onClick={(e) => onChangeLocale(e, 'pt')}
                                href="#"
                            >
                                Português
                            </a>
                        </div>
                        <div className="gx-d-block gx-d-md-inline-block gx-float-md-right gx-mt-4 gx-mt-md-0">
                            <Link to="/legal/terms-conditions">{t('terms-conditions')}</Link>
                            &nbsp;<span className="gx-mr-1 gx-ml-1">|</span>&nbsp;
                            <Link to="/legal/privacy">{t('privacy')}</Link>
                            &nbsp;<span className="gx-mr-1 gx-ml-1">|</span>&nbsp;
                            <Link to="/legal/cookies">{t('cookie_policy')}</Link>
                        </div>
                    </Col>
                </Row>
            </div>
        </Layout.Footer>
    );
};

export default Footer;
