import { ConfigProvider, Layout } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import App from "routes/index";
import { LOADING } from "../../constants/App";
import { TAB_SIZE } from "../../constants/ThemeSetting";
import HomeSlider from '../../routes/Home/components/Slider';
import Sidebar from "../Sidebar/index";
import KsHeader from "../Topbar/Header/index";
import Footer from './Footer';
import { Claim } from "../../routes/Home/components/Claim";
import { useTranslation } from 'react-i18next';
import { FullPageLoader } from "../../components/CircularProgress";
import { get } from 'lodash';
import moment from 'moment';
import ScrollToTop from "../../components/Layout/ScrollToTop";
import { DefaultHead } from "./Head";
import AppLocale from "lngProvider";
import CookieConsent from "../../components/CookieConsent";

const { Content } = Layout;

const MainApp = (props) => {

    const { t, i18n } = useTranslation();

    const navStyle = useSelector(({ settings }) => settings.navStyle);
    const width = useSelector(({ settings }) => settings.width);
    const user = useSelector(({ user }) => user);
    const auth = useSelector(({ auth }) => auth);
    const morph = useSelector(({ morph }) => morph);

    useEffect(() => {
        moment.locale(i18n.language);
    }, [i18n])

    if (user === LOADING) return <FullPageLoader />;

    const getContainerClass = () => {
        return "gx-container-wrap";
    };

    const getNavStyles = () => {
        return <KsHeader user={user} morph={morph} auth={auth} t={t} />;
    };

    const getSidebar = (width) => {
        if (width < TAB_SIZE) {
            return <Sidebar user={user} />;
        }
    };

    const { match, location } = props;

    let currentAppLocale = AppLocale[i18n.language];

    if(!currentAppLocale){
        currentAppLocale = AppLocale['en'];
    }

    return (
        <ConfigProvider locale={currentAppLocale.antd}>
            <DefaultHead language={i18n.language} t={t} />
            <div >
                {getSidebar(width)}
                <Layout className="gx-app-layout" style={{ height: "100vh" }}>
                    {getNavStyles(navStyle)}
                    {get(location, 'pathname', '').toLowerCase() === '/home' &&
                        <>
                            <HomeSlider />
                            <div className="gx-d-block gx-d-sm-none">
                                <Claim t={t} />
                            </div>
                        </>
                    }
                    <Content className={`gx-layout-content ${getContainerClass(navStyle)}`}>
                        <ScrollToTop />
                        <App match={match} morph={morph} user={user} {...props} />
                        <Footer />
                    </Content>
                </Layout>
                <CookieConsent />
            </div>
        </ConfigProvider >
    )
};


export default MainApp;

